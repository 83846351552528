@import '../../theme.module.scss';

.mainContainer {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $white;

    .navbarContainer {
        height: $homeNavbarHeight;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px 50px;
    }
}