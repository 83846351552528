@import '../../theme.module.scss';

.navbar {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    z-index: 2;

    .userPreviewContainer {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}