@import "../../theme.module.scss";

.loaderContainer {
  height: 10%;
  width: 10%;
}

.background {
  height: 100%;
  background-color: $white;
  overflow: hidden;

  .mainContainer {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 15px 50px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;

    .progressBarContainer {
      flex: 1;
      max-width: 390px;
    }
  }
}

.topContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;

  .textContainer {
    display: flex;
    align-items: center;

    .subtitle {
      margin-left: 20px;
      max-width: 460px;
    }
  }
}
