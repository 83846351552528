/* COLORS */
$primary: #2E54C8;
$secondary: #279DDF;
$accent1: #6E50BC;
$accent2: #1EBB60;
$error: #E40A3F;

$white: #F8F8F8;
$pureWhite: #FFFFFF;
$black: #2B2A2A;
$gray: #817E7E;
$grayLight: #d4d3d3;

$primaryLight: #2259fe83;


$boxShadowBlackColor: rgba(129, 126, 126, 0.4);
$shadowL: 4px 2px 70px rgba(43, 42, 42, 0.4);
$shadowS: 4px 2px 60px rgba(43, 42, 42, 0.2);

$cardContainerShadow: rgba(43, 42, 42, 0.4);

// $inputBackgroundColor: rgba(34, 88, 254, 0.2);
$inputBackgroundColor: $white;
$inputInactiveBorderColor: rgba(34, 89, 254, 0.3);

//NAVBAR
$homeNavbarHeight: 80px;


/* FONTS */
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$primaryFont: 'Red Hat Display', sans-serif;
$largeTitleFontSize: 80px;
$mediumTitleFontSize: 70px;
$smallTitleFontSize: 50px;
$loginFontSize: 22px;
$largeSubtitleFontSize: 40px;
$mediumSubtitleFontSize: 30px;
$smallSubtitleFontSize: 26px;
$largeParagraphFontSize: 18px;
$mediumParagraphFontSize: 16px;
$smallParagraphFontSize: 14px;

$whiteButtonFontSize: 24px;

$sectionButtonFontSize: 20px;

$regularFontWeight: 300;
$boldFontWeight: 400;
$blackFontWeight: 700;
$extraBoldFontWeight: 900;


/* FORM */
$borderSize: 2px;
$borderRadius: 6px;


/* EXPORTS */
:export {
    colors: {
        primary: $primary;
        secondary: $secondary;
        accent1: $accent1;
        accent2: $accent2;
        white: $white;
    }
}
