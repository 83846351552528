@import '../../../theme.module.scss';

.roundedCover {
    position: absolute;
    height: 200px;
    width: 200px;
    background-color: $black;
    border-radius: 100%;
    bottom: 15%;
    right: 15%;
    z-index: 100;
}