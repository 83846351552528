@import '../../../theme.module.scss';

.title {
    font-size: $largeTitleFontSize;
    font-weight: $blackFontWeight;

    .titleEndCharacter {
        color: $secondary;
    }
}

.subtitle {
    margin-top: 20px
}