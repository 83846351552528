$gapBetweenCards: 15px;

.mainContainer {
  position: relative;
  height: 100%;
  width: 100%;
  gap: $gapBetweenCards;
  display: flex;
  flex-direction: column;
  padding: 20px 40px;

  z-index: 0;

  > div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $gapBetweenCards;
  }
}
