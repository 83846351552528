@import "../../theme.module.scss";

.mainContainer {
  .iconContainer {
    height: 28px;
    width: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: all 0.2s;
    padding: 4px;
    border-radius: $borderRadius;

    &:hover {
      cursor: pointer;
      background-color: $grayLight;
    }

    &:active {
      background-color: $gray;
    }

    div {
      height: 5px;
      width: 5px;
      border-radius: 100%;
      background-color: $gray;
      transition: all 0.3s;
    }
  }

  .listContainer {
    position: absolute;
    width: calc(100% + 20px);
    border-radius: $borderRadius;
    max-height: 0;
    top: 0;
    left: -10px;
    overflow: hidden;

    background-color: $pureWhite;

    transition: all 0.3s;

    &.open {
      max-height: 200px;
    }

    ul {
      margin-top: 20px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 5px;

      li {
        list-style: none;
        width: max-content;
        transition: all 0.3s;
        border-bottom: 2px solid transparent;

        &:hover {
          cursor: pointer;
          border-bottom: 2px solid $gray;
        }
      }
    }

    .closeIconContainer {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 10px;
      right: 10px;
      margin-left: "auto";
      align-self: flex-end;
      border-radius: $borderRadius;

      &:hover {
        background-color: $gray;
        cursor: pointer;

        div {
          background-color: $white;
        }
      }

      div {
        top: 3px;
        position: absolute;
        height: calc(100% - 4px);
        width: 3px;
        border-radius: 50px;

        background-color: $gray;

        &.first {
          left: 50%;
          transform: rotate(45deg) translateX(-50%);
        }

        &.second {
          right: 50%;
          transform: rotate(-45deg) translateX(50%);
        }
      }
    }
  }
}
