@import '../../../theme.module.scss';

.mainContainer {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  margin: 50px;
  overflow: hidden;

  .selectedImageContainer {
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    margin: 0 auto;
    aspect-ratio: 1;
  }
}
