@import "../../../theme.module.scss";

.richTextContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .breakLineContainer {
    display: flex;
    flex-direction: column;
  }
}

/* FONT WEIGHT */

.regularWeight {
  font-weight: $regularFontWeight;
}

.boldWeight {
  font-weight: $boldFontWeight;
}

.blackWeight {
  font-weight: $blackFontWeight;
}

.extraBoldWeight {
  font-weight: $extraBoldFontWeight;
}

/* FONT COLOR */

.primary {
  color: $primary;
}

.secondary {
  color: $secondary;
}

.accent1 {
  color: $accent1;
}

.accent2 {
  color: $accent2;
}

.black {
  color: $black;
}

.white {
  color: $white;
}

.gray {
  color: $gray;
}

.error {
  color: $error;
}

/* FONT SIZE */
.title {
  &.small {
    font-size: $smallTitleFontSize;
  }

  &.medium {
    font-size: $mediumTitleFontSize;
  }

  &.large {
    font-size: $largeTitleFontSize;
  }
}

.subtitle {
  &.small {
    font-size: $smallSubtitleFontSize;
  }

  &.medium {
    font-size: $mediumSubtitleFontSize;
  }

  &.large {
    font-size: $largeSubtitleFontSize;
  }
}

.paragraph {
  &.small {
    font-size: $smallParagraphFontSize;
  }

  &.medium {
    font-size: $mediumParagraphFontSize;
  }

  &.large {
    font-size: $largeParagraphFontSize;
  }
}

/* ALIGN */
.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

/* SPAN TAG */

.span {
  font-weight: inherit;
  font-size: inherit;
}
