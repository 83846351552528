@import '../../../theme.module.scss';

.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    overflow: hidden;
    box-shadow: 5px 4px 26px -2px rgba(0,0,0,0.25);
    background-color: $white;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.small {
        width: 40px;
        height: 40px;
        box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    }

    &.medium {
        width: 90px;
        height: 90px;
    }

    &.large {
        width: 100px;
        height: 100px;
    }

    &.xlarge {
        width: 140px;
        height: 140px;
    }

    &.primary {
        border: 2px solid $primary
    }

    &.secondary {
        border: 2px solid $white
    }
}