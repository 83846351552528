@import '../../theme.module.scss';

.mainContainer {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    
    .navbarContainer {
        position: absolute;
        height: 80px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        top: 0;
        left: 0;
        padding: 0 50px;
    }

    .backHomeButton {
        position: absolute;
        bottom: 25px;
        left: 50px;
    }
}