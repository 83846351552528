@import '../../../theme.module.scss';

.button {
  border-radius: $borderRadius;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  
  &.violet {
    background-color: $inputBackgroundColor;
    color: $gray;
    font-weight: $boldFontWeight;
    font-size: $smallParagraphFontSize;
    min-height: 45px;
    min-width: 45px;
    border: 1px solid $inputInactiveBorderColor;

    &.selected {
      background-color: $accent1;
      color: $white;
      border: none;
    }
  }

  &.white {
    min-height: 100px;
    min-width: 280px;
    background-color: $white;
    color: $accent1;
    font-weight: $blackFontWeight;
    font-size: $whiteButtonFontSize;
  }

  &.blue {
    min-height: 50px;
    min-width: 380px;
    padding: 15px 35px;
    background-color: $primary;
    color: $white;
    font-weight: $boldFontWeight;
    font-size: $mediumParagraphFontSize;
  }
}
