.formContainer {
    margin-top: 28px;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.sideContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .birthdayContainer > input {
        margin-right: 20px;
    }
}

.title {
    margin-bottom: 12px;
}