@import '../../../theme.module.scss';

.uploadImageButtonContainer {
  height: 140px;
  width: 140px;
  border: $borderSize solid $white;
  background-color: $white;
  border-radius: 100%;
  color: $primary;
  font-size: $largeParagraphFontSize;
  font-weight: $blackFontWeight;
  cursor: pointer;
  overflow: hidden;

  .uploadImageInnerButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    border: $borderSize solid $primary;
    border-radius: 100%;
  }
}

.profileImage {
  height: 100%;
}
