@import '../../theme.module.scss';

.container {
    background: $white;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.loginComponentsContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    max-height: 1080px;
    max-width: 1920px;
}

.rightContainer {
    margin-top: 10%;
    position: relative;
}

.leftContainer {
    max-width: 450px;
    margin-bottom: 5%;
    position: relative;
}