@import "../../theme.module.scss";

.mainContainer {
  height: 100%;
  width: 100%;
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconImageContainer {
  position: absolute;
}

.uploadImageContainer {
  position: absolute;
  top: 50%;
}

//Circle transition

.roundedCover {
  position: absolute;
  height: 200px;
  width: 200px;
  background-color: $black;
  border-radius: 100%;
  bottom: 15%;
  right: 15%;
  z-index: 100;
}

// Loading
.mainLoadingContainer {
  width: 100%;
  height: 100%;
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinnerContainer {
    height: 160px;
    width: 160px;
  }
}
