@import '../../../theme.module.scss';

.container {
    position: relative;
    z-index: 2;
}

.formContainer {
    width: 380px;
    height: 240px;
    background-color: $white;
    border-radius: $borderRadius;
    box-shadow: 5px 4px 26px -2px rgba(0,0,0,0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 50px 10px 68px;
}

.button {
    position: absolute;
    transition: all 0.1s;
    bottom: -40px;
    left: calc(50% - 40px);
}

.buttonContent {
    transition: all 0.1s;
}