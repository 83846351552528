@import '../../../theme.module.scss';

$maxImageWidth: 280px;

.mainContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    height: 100%;
    top: 0;
    width: 100%;
    max-width: $maxImageWidth;

    &.left {
        left: 0;
    }

    &.right {
        right: 0;
    }

    .imageContainer {
        position: relative;
        height: 50%;
        width: min-content;
        max-width: $maxImageWidth;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: $shadowL;
        border-radius: $borderRadius;
        overflow: hidden;

        img {
            height: 100%;
        }
    }
}