@import '../../../theme.module.scss';

.input {
  border-radius: $borderRadius;
  color: $black;
  outline: none;
  padding: 0 12px;
}

.primary {
  border: $borderSize solid $secondary;
}

.secondary {
  border: none;
  background-color: $inputBackgroundColor;
  border: $borderSize solid $inputInactiveBorderColor;

  &.white {
    background-color: $white;
    border: $borderSize solid $accent1;
  }

  &:focus {
    border: $borderSize solid $primary;
  }
}

.small {
  height: 45px;
  width: 80px;
  font-size: 16px;
}

.medium {
  height: 50px;
  width: 220px;
  font-size: 22px;
}

.large {
  height: 45px;
  width: 300px;
  font-size: 16px;
}

.black {
  font-weight: $blackFontWeight;
}

.bold {
  font-weight: $boldFontWeight;
}

.regular {
  font-weight: $regularFontWeight;
}

.textarea {
  width: 300px;
  height: 155px;
  resize: none;
  padding: 10px;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.disabled {
  border: 2px solid $gray;
  pointer-events: none;
}