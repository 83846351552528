@import '../../theme.module.scss';

.mainContainer {
    width: 100vw;
    height: 100vh;
    background-color: $primary;

    padding: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    .logoContainer {
        position: absolute;
        top: 25px;
        left: 25px;
    }
}