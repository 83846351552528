@import '../../../theme.module.scss';

.mainContainer {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: $white;
    box-shadow: $shadowS;
    border-radius: $borderRadius;
    padding: 20px;
    padding-left: 25%;

    .innerContainer {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;
        gap: 12px;
    }

    .imageContainer {
        position: absolute;
        height: 170px;
        width: 180px;
        border-radius: $borderRadius;
        box-shadow: $shadowL;

        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        bottom: 40%;
        left: -10%;

        img {
            height: 100%;
        }
    }

    .finishButton {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(90%, -50%)
    }
}