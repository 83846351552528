@import "../../../theme.module.scss";

.mainContainer {
  position: relative;
  height: 100%;
  width: 350px;
  border-radius: $borderRadius;
  box-shadow: $shadowS;
  background-color: $white;

  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .textContainer {
    .titleContainer {
      display: flex;
      align-items: start;
      justify-content: space-between;
      margin-bottom: 20px;

      .title {
        flex: 1;
      }

      .extraImage {
        flex: 1;
        height: 80px;
        min-width: 80px;
        max-width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: $borderRadius;
        box-shadow: $shadowL;

        z-index: 2;

        img {
          height: 100%;
        }
      }
    }
  }

  .imagesContainer {
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .image {
      height: 120px;
      width: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: $borderRadius;
      box-shadow: $shadowL;

      img {
        height: 100%;
      }
    }
  }
}
