@import '../../../theme.module.scss';

.mainContainer {
    width: 720px;
    height: 400px;
    
    &:not(.empty) {
        padding: 20px;
        background-color: $white;
        display: flex;
        flex-direction: column;
        border-radius: $borderRadius;
        box-shadow: 4px 2px 70px 0px rgba(0,0,0,0.4);
    }
}