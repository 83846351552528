@import "../../../theme.module.scss";

.mainContainer {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .subtitleContainer {
    margin-top: 15px;
  }

  .sizeTable img {
    margin-top: 15px;
  }

  .buttons {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .imagesContainer {
    pointer-events: none;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    position: absolute;

    div {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: $shadowL;
      overflow: hidden;
      border-radius: $borderRadius;

      &.shirt1 {
        height: 225px;
        width: 225px;
        bottom: 5%;
        right: 15%;
      }

      &.shirt2 {
        height: 165px;
        width: 165px;
        bottom: -15%;
        right: 0;
        z-index: 1;
      }

      img {
        height: 100%;
      }
    }
  }
}
