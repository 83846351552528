@import '../../../theme.module.scss';

.mainContainer {
    position: relative;
    width: 100%;
    height: 100%;

    .textContainer {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        position: relative;
        height: 100%;

        &.right {
            padding-left: 45%
        }

        &.left {
            padding-right: 45%
        }

        .title {
            margin: 20px 0 30px 0;
        }

        .description {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: start;
            gap: 20px;

            text-align: justify;

            overflow: scroll;
            height: 100%;
        }
    }
}