@import '../../../theme.module.scss';

.button {
  border-radius: $borderRadius;
  border: none;
  cursor: pointer;
  text-align: center;
  font-weight: $boldFontWeight;
  font-size: $mediumParagraphFontSize;
  padding: 16px 35px;

  &.large {
    min-height: 50px;
    min-width: 380px;
  }

  &.violet {
    background-color: $accent1;
    color: $white;
  }

  &.white {
    background-color: $white;
    color: $accent1;
  }

  &.blue {
    background-color: $primary;
    color: $white;
  }

  &.disabled {
    cursor: none;
    pointer-events: none;
    background-color: $gray
  }
}
