@import '../../../theme.module.scss';

.mainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    border: 1px solid $primary;
    border-radius: $borderRadius;
    transition: all 0.1s;

    img {
        opacity: 0;
        transition: all 0.1s
    }

    &.done {
        background-color: $primary; 
        
        img {
            opacity: 1;
        }
    }
    
    &:hover {
        cursor: pointer;

        img {
            opacity: 1;
        }
    }

    &.disabled {
        background-color: $gray;
        border-color: $gray;

        &:hover {
            cursor: not-allowed;
        }
    }
}