.mainContainer {
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .carouselContainer {
    height: 400px;
    width: 720px;
    position: relative;
    display: flex;

    .button {
      position: absolute;
      top: calc(50% - 40px);

      &.left {
        left: -100px;
        z-index: 1;
      }

      &.right {
        right: -100px;
      }
    }

    .formCarouselContainer {
      position: absolute;

      &.blur {
        filter: blur(2px);
        pointer-events: none;
      }
    }
  }
}
