.mainContainer {
    display: flex;
    width: min-content;
    align-items: center;
    cursor: pointer;
    gap: 6px;

    &:hover .backArrow {
        transform: translateX(-10px) scale(1.5)
    }

    .backArrow {
        transition: all 0.2s;
    }

    .text {
        white-space: nowrap;
    }
}