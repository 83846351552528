@import '../../../theme.module.scss';

.mainContainer {
    height: 100px;
    width: 280px;
    box-shadow: 4px 2px 70px 0px $boxShadowBlackColor;
    background-color: $white;
    border-radius: $borderRadius;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: $blackFontWeight;
    font-size: $sectionButtonFontSize;
    border: none;

    transition: all 0.1s ease-in-out;

    &:hover {
        cursor: pointer;
    }

    &.primary {
        color: $primary;

        &:hover {
            border: 2px solid $primary;
        }
    }

    &.secondary {
        color: $secondary;

        &:hover {
            border: 2px solid $secondary;
        }
    }

    &.accent1 {
        color: $accent1;

        &:hover {
            border: 2px solid $accent1;
        }
    }

    &.accent2 {
        color: $accent2;

        &:hover {
            border: 2px solid $accent2;
        }
    }
}