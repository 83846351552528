@import '../../../theme.module.scss';

.mainContainer {
  position: absolute;
  top: calc(50% - 40vh);
  left: calc(50% - 30vw);
  height: 80vh;
  width: 60vw;
  background-color: $white;
  border-radius: $borderRadius;
  padding: 20px;

  .innerContainer {
    height: 100%;
    width: 100%;
    padding: 20px;
    border-radius: $borderRadius;
    border: $borderSize dashed $gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s;

    &.focused {
      border-color: $primary;
    }

    .textContainer {
      margin-top: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .fileSelectorButton {
        border-radius: $borderRadius;
        cursor: pointer;
        text-align: center;
        font-weight: $boldFontWeight;
        font-size: $mediumParagraphFontSize;
        min-height: 50px;
        min-width: 380px;
        padding: 16px 35px;
        background-color: $primary;
        color: $white;
      }

      .subtitle {
        margin: 20px 0;
      }
    }
  }
}
