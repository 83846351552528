.mainContainer {
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  
    .carouselContainer {
      height: 400px;
      width: 720px;
      position: relative;
      display: flex;
  
      .button {
        position: absolute;
        top: calc(50% - 40px);
        border-radius: 100%;
  
        &.left {
          left: -100px;
          z-index: 1;
        }
  
        &.right {
          right: -100px;
        }
      }
  
      .teamUsersContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .imagesContainer {
          width: 100%;
          position: relative;
        }
      }
    }
  }
  