.mainContainer {
  margin: 40px 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .titleContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    .progressBarContainer {
      margin-top: 20px;
      width: 100%;
      max-width: 390px;
    }
  }

  .nextSectionContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    gap: 20px;
    justify-content: center;
    align-items: center;

    .button {
      margin: 0 10px;
    }
  }
}
