@import '../../../theme.module.scss';

.imageContainer {
  position: relative;

  .image {
    height: 215px;
    width: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: $borderRadius;
    box-shadow: 5px 4px 26px -2px rgba(0, 0, 0, 0.25);

    img {
      height: 215px;
      width: auto;
    }
  }

  .buttonContainer {
    position: absolute;
    bottom: -40px;
    left: calc(50% - 40px);
    height: 80px;
    width: 80px;
    transition: all 0.2s ease-out;
    z-index: 1;

    &:not(.disabled):hover {
      transform: scale(1.2)
    }

    .buttonInnerContainer {
      position: absolute;
      width: 100%;
      height: 100%;
      transform-style: preserve-3d;
      transition: all 1s;

      &.disabled {
        cursor: not-allowed
      }

      &.selected {
        transform: rotateY(180deg);
      }
    }

    .button {
      box-shadow: 5px 4px 26px -2px rgba(0, 0, 0, 0.25);
      position: absolute;
      backface-visibility: hidden;

      &:disabled {
        cursor: not-allowed
      }

      &.back {
        z-index: 1;
        transform: rotateY(180deg);
      }
    }
  }
}
