.formContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .subtitleContainer {
        margin-top: 15px;
    }

    .computerContainer {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .computerInnerContainer {
            display: flex;
            justify-content: space-around;
        }
    }
}