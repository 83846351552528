@import '../../../theme.module.scss';

.container {
    height: 80px;
    width: 80px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);

    .iconContainer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.fullShadow {
        box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.25);
    }

    &.black {
        background-color: $black
    }

    &.white {
        background-color: $white
    }

    &.check {
        background: $accent2;
    }

    &.grayBackground {
        background-color: $gray;
    }

    &:disabled {
        cursor: not-allowed;
    }

    .left {
        transform: rotate(180deg)
    }

    .up {
        transform: rotate(-90deg)
    }

    .down {
        transform: rotate(90deg)
    }
}

.loadingSpinnerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    width: 50%;
}

:export {
    primaryColor: $primary;
    blackColor: $black;
}

