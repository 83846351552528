@import '../../../theme.module.scss';

.mainContainer {
    width: 100%;

    display: flex;
    gap: 12px;

    .checkboxContainer {
        min-height: 30px;
        min-width: 30px;
        height: 30px;
        width: 30px;
        position: relative;
    }

    .textContainer {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }
}