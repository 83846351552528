@import '../../../theme.module.scss';

.formContainer {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .subtitleContainer {
        margin-top: 15px;
    }

    .innerFormContainer {
        display: flex;
        align-items: center;
        height: 100%;
        margin-top: auto;

        .form {
            width: 100%;
            height: 100%;
            max-height: 185px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

.subtitlePencilContainer {
    display: flex;
    align-items: center;
}

.sideContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}