@import '../../../theme.module.scss';

.mainContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .formDataContainer {
        height: 100%;
        width: 100%;
    }
}