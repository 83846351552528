@import '../../../theme.module.scss';

.mainContainer {
    width: 220px;
    height: 380px;

    max-height: 80px;
    max-width: 80px;
    border-radius: $borderRadius;
    box-shadow: $shadowL;

    display: flex;
    align-items: start;
    justify-content: center;

    overflow: hidden;
    transition: all 0.3s;

    &.active {
        max-height: 380px;
        max-width: 220px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}