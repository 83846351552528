@import '../../../theme.module.scss';

.progressBar {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 390px;
    height: 6px;
    margin-bottom: 6px;
    border-radius: 6px;
    overflow: hidden;
    
    .progressBarUnfilled {
        opacity: 0.5;
        width: 0;
    }

    .progressBarFilled {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 0%;
        transition: width 0.5s;
    }
}

.primary {
    background-color: $primary
}

.secondary {
    background-color: $secondary
}

.accent1 {
    background-color: $accent1
}

.accent2 {
    background-color: $accent2
}

.white {
    background-color: $white
}