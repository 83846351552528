@import '../../../theme.module.scss';

.mainContainer {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;

    &:hover {
        cursor: pointer;
    }
}

.innerContainer {
    width: 100%;
    height: 100%;
    padding: 20px 30% 20px 20px;
    border-radius: $borderRadius;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
        margin-bottom: 10px;
    }

    .shapesCenterHelper {
        top: 50%;
        right: 0;
        position: absolute;
        z-index: 10;
    }
}

.violet {
    background-color: $accent1;
}

.skyblue {
    background-color: $secondary;
}

.blue {
    background-color: $primary;
}

.green {
    background-color: $accent2;
}