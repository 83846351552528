@import '../../../theme.module.scss';

.mainContainer {
    height: 300px;
    width: 720px;

    box-shadow: $shadowS;
    border-radius: $borderRadius;
    background-color: $white;

    padding: 20px;
    padding-left: 260px;

    display: flex;
    flex-direction: column;

    gap: 18px;

    .nameAndLinksContainer {
        display: flex;
        align-items: start;
        justify-content: space-between;
        margin-bottom: 6px;

        .linksContainer {
            display: flex;
            align-items: center;
            gap: 18px;
        }
    }

    .descriptionContainer {
        height: 100%;
        overflow: scroll;
    }
}